@import "src/mixins/screen-size";
@import "../../assets/scss/theme.scss";

.wallet-btn {
  position: relative !important;
  vertical-align: middle !important;
  font-family: "Jost";
  padding: 0% !important;
  margin-left: auto;
  color: $secondary !important;
}

.btn-outline-primary {
  border: none;
  outline: none;
  position: relative;
  z-index: 0;
  border-radius: 5px;
  background: linear-gradient(to right, $primary-light, $green);
  cursor: pointer;
}

.btn-outline-primary::before {
  content: "";
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: 4px;
  background-color: $primary;
  z-index: -1;
  transition: 200ms;
}
.btn-outline-primary::after {
  content: attr(data);
  font-size: 18px;
  background: linear-gradient(to left, $primary-light, $green);
  -webkit-background-clip: text;
  color: transparent;
  transition: 200ms;
}
.btn-outline-primary:hover::before {
  opacity: 50%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.btn-outline-primary:hover::after {
  color: white;
}

.link-button {
  display: initial !important;
}

.header-buttons {
  margin: auto !important;
}

.MuiButton {
  color: white !important;
  vertical-align: middle !important;
  height: 40px !important;
}

.blur {
  color: transparent;
  text-shadow: 0 0 8px black;
}

.widget-value {
  color: $secondary !important;
}

.docs-row-margin {
  margin-bottom: 15px !important;
}

.expand-btn {
  cursor: pointer;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  right: 0;
  position: relative;
  margin-top: 10px;
  margin-right: 10px;
}

.expand-btn:hover {
  color: $primary !important;
}

.popover {
  max-width: 100% !important;
}

.settings-dropdown-menu {
  left: auto !important;
  right: 0 !important;
}

.dropdown-item.active {
  color: $primary !important;
}

.card {
  border-radius: 20px !important;
}

//

.fade {
  z-index: 1 !important;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9990;
}

#custom-prompt {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  width: 400px;
  @include respond-to(sm) {
    width: 350px;
  }
}

#custom-prompt label {
  display: block;
  margin-bottom: 10px;
}

#custom-prompt input[type="text"] {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
}

.buttons-container button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#cancel-button {
  color: black;
  margin-right: 10px;
  cursor: pointer;
}

#confirm-button {
  color: white;
}

#error-message {
  color: #f44336;
  margin-top: 10px;
  display: none;
}

.buttons {
  text-align: end;
}

.read-only {
  color: rgb(255, 115, 0);
  font-size: 0.75rem;
  text-align: center;
}

.white-text {
  color: white;
}

.fade {
  z-index: 1 !important;
}

.modal-title-centered {
  text-align: center !important;
  width: 100%;
}

#p-without-margin {
  margin: 0px;
}
